import { useMutation } from '@tanstack/react-query';
import { api } from '../services/Api';

export const useConfirmInvitationMutation = ({
  onError,
  onSuccess,
}: {
  onError?: (error: any) => void;
  onSuccess?: () => void;
} = {}) => {
  return useMutation({
    mutationFn: async ({ hash, whipId }: { hash: string; whipId: string }) => {
      try {
        const response = await api.postConfirmInvitation({
          hash,
          whipId,
        });
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    retry: false,
    onError,
    onSuccess,
  });
};
