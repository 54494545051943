import axios from 'axios';

const request = axios.create();

request.interceptors.response.use(
  response => {
    return response;
  },
  async error => {
    return Promise.reject(error);
  },
);

console.log(process.env.NODE_ENV);

const apiURLLocal = (pathName: string) =>
  `http://127.0.0.1:5001/whipapp-f3728/europe-west2/${pathName}`;

const apiURLRemote = (pathName: string) =>
  `https://${pathName.toLowerCase()}-qsezx5zk6a-nw.a.run.app`;

export const setAPIUrl = (pathName: string) =>
  process.env.NODE_ENV === 'development' ?
    apiURLLocal(pathName)
  : apiURLRemote(pathName);

export const api = {
  getInvitationStatus: async ({
    hash,
    whipId,
  }: {
    hash: string;
    whipId: string;
  }) => {
    try {
      const response = await request.get(
        `${setAPIUrl('getInvitationStatus')}?whipId=${whipId}`,
        {
          headers: {
            'whip-hash-code': hash,
          },
        },
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  postConfirmInvitation: async ({
    hash,
    whipId,
  }: {
    hash: string;
    whipId: string;
  }) => {
    try {
      const response = await request.post(
        `${setAPIUrl('publicPostConfirmInvitation')}`,
        {
          whipId,
        },
        {
          headers: {
            'whip-hash-code': hash,
          },
        },
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  getWhip: async ({
    accessToken,
    whipToken,
  }: {
    accessToken: string;
    whipToken: string;
  }) => {
    try {
      const response = await request.get(`${setAPIUrl('getWhip')}`, {
        headers: {
          authorization: accessToken,
          'whip-token': whipToken,
        },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  getPaymentIntent: async ({
    amount,
    accessToken,
    whipToken,
  }: {
    amount: number;
    accessToken: string;
    whipToken: string;
  }) => {
    try {
      const response = await request.post(
        `${setAPIUrl('createPaymentIntent')}`,
        {
          amount,
        },
        {
          headers: {
            authorization: accessToken,
            'whip-token': whipToken,
          },
        },
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};
