import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { App } from './App';
import './components/index.css';
import reportWebVitals from './reportWebVitals';

const firebaseConfig = {
  apiKey: 'AIzaSyD14VrGoTyAJEpjwaVOHcr50qHbKJDHcdI',
  appId: '1:1003730556320:web:08209df38f1687e99540c5',
  authDomain: 'whipapp-f3728.firebaseapp.com',
  measurementId: 'G-WGHBNHMVX9',
  messagingSenderId: '1003730556320',
  projectId: 'whipapp-f3728',
  storageBucket: 'whipapp-f3728.appspot.com',
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);

export const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <App />
    </QueryClientProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
