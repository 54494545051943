import React from 'react';
import { Bounce, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useConfirmInvitationMutation } from './queries/useConfirmInvitationMutation';

const logo = require('./whipappLogoWhite.svg').default;

export const App = () => {
  const [success, setSuccess] = React.useState(false);
  const [error, setError] = React.useState(false);

  const whipId = location.pathname.split('/join/')[0].split('/')[1];
  const hash = location.pathname.split('/join/')[1];

  const confirmInvitationMutation = useConfirmInvitationMutation({
    onError: () => {
      setError(true);
      console.log('error');
      toast.error(
        'An error occurred! Please try again or contact your Friend who invited you.',
        {
          position: 'top-center',
          autoClose: false,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: 'colored',
          transition: Bounce,
        },
      );
    },
    onSuccess: () => {
      setSuccess(true);
      console.log('success');
      toast.success(
        'Invitation accepted! You can now see the Whip on your My Whips screen.',
        {
          position: 'top-center',
          autoClose: false,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: 'colored',
          transition: Bounce,
        },
      );
    },
  });

  React.useEffect(() => {}, []);

  return (
    <div className="mainContainer">
      <ToastContainer
        position="top-center"
        autoClose={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        theme="colored"
        transition={Bounce}
      />
      <div className="mainHeader">
        <img src={logo} alt="logo" />
      </div>
      <header className="secondaryHeader">
        <h1>Welcome to Whipapp!</h1>
      </header>
      <h2 className="disclaimer-title">Why am I here?</h2>
      <p className="disclaimer">You are here because a friend</p>
      <p className="disclaimer">invited you to join a Whip.</p>
      <div className="cta-container">
        <button
          className="button"
          disabled={confirmInvitationMutation.isPending || success}
          onClick={() => {
            confirmInvitationMutation.mutate({ hash, whipId });
          }}
        >
          {confirmInvitationMutation.isPending ?
            'Verifying...'
          : 'Accept Invitation'}
        </button>
      </div>
    </div>
  );
};
